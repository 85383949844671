.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is on top of other content */
}

.modal {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  height: 80%;
  max-height: 600px;
  overflow-y: hidden;
  z-index: 1001;
}

.close {
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
  font-size: 40px;
  z-index: 1002; /* Ensure the close button is on top of the modal content */
}

.scrollable-content {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: calc(100% - 80px);
}

.guide-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: calc(100% - 100px);
}

.guide-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
  width: 90%;
}

.column-header {
  font-weight: bold;
  flex-basis: 22%;
  text-align: left;
  margin-right: 20px;
}

.value-header {
  font-weight: bold;
  flex-basis: 45%;
  text-align: left;
  margin-right: 20px;
}

.description-header {
  font-weight: bold;
  flex-basis: 70%;
  text-align: left;
}

.guide-cell {
  flex: 1;
  padding: 8px;
  text-align: left;
}

.value-cell {
  flex-grow: 2;
  padding-right: 20px;
}

.description-cell {
  flex-grow: 3;
  padding-right: 20px;
}

.form-divider {
  border: 0;
  border-top: 1px solid #ccc;
  width: 90%;
  margin: 10px 0;
}
