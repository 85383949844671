th.k-header.active > div > div {
  color: #fff;
  background-color: #0d5719;
}

th.check-box {
  color: #000a00;
  background-color: #146e14;
}

th.active1 {
  background-color: aqua;
}

.k-grid-column-menu.k-grid-column-menu .k-icon::before {
  content: "\e129";
  color: #999;
}

th.k-header.active .k-grid-column-menu {
  content: "\e129";
  color: #333;
  background-color: #0b618a;
}
