body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

td.trunc-col {
  white-space: nowrap;
  text-overflow: ellipsis;
  table-layout: fixed;
}

.k-grid thead {
  background-color: rgba(8, 1, 4, 0.062);
  /* font-size: 1.2em; */
}

.k-grid-header .k-header {
  overflow: visible;
  white-space: normal;
}

.k-editor .k-editor-content p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.scrollable-treeview {
  height: calc(100vh - 280px);
}

.k-animation-container-shown {
  z-index: 2000 !important;
}

.k-treeview-item .k-animation-container {
  overflow: inherit;
}

.k-treeview-leaf.k-selected {
  color: #0b618a;
}

.k-table-tbody .k-table-row:hover {
  background-color: inherit; /* tb detail background color remains the same on hover */
}
